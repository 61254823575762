
const educOpts = [{
    label:'小学',
    value:'小学'
},{
    label: '初中',
    value: '初中'
},{
    label: '高中',
    value: '高中'
},{
    label: '大专',
    value: '大专'
},{
    label: '本科',
    value: '本科'
},{
    label: '研究生',
    value: '研究生'
},{
    label: '博士',
    value: '博士'
}]

const marrOpts = [{
    label: '未婚未育',
    value: '未婚未育'
},{
    label: '已婚未育',
    value: '已婚未育'
},{
    label: '已婚已育',
    value: '已婚已育'
},{
    label: '离异',
    value: '离异'
},{
    label: '其他',
    value: '其他'
}]

const politicOpts = [{
    label: '群众',
    value: '群众'
},{
    label: '团员',
    value: '团员'
},{
    label: '党员',
    value: '党员'
},{
    label: '民族党派',
    value: '民族党派'
},{
    label: '其他',
    value: '其他'
}]

const handBookOpts = [{
    label: '已交',
    value: '已交'
},{
    label: '未交',
    value: '未交'
},{
    label: '来沪',
    value: '来沪'
}]

const bloodOpts = [{
    label: 'A',
    value: 'A'
},{
    label: 'B',
    value: 'B'
},{
    label: 'O',
    value: 'O'
},{
    label: 'AB',
    value: 'AB'
},{
    label: '其他',
    value: '其他'
}]

const labOpts =  [{
    label: '劳动合同',
    value: 4
},{
    label: '返聘协议',
    value: 1
},{
    label: '实习协议',
    value: 2
},{
    label: '劳务协议',
    value: 3
}]

const signOpts = [{
    label: '第一签',
    value: '第一签'
},{
    label: '第二签',
    value: '第二签'
},{
    label: '第三签',
    value: '第三签'
}]

const leavOpts = [{
    label: '增加调休',
    value: 0
},{
    label: '调休',
    value: 1
},{
    label: '年假',
    value: 2
},{
    label: '事假',
    value: 3
},{
    label: '病假',
    value: 4
},{
    label: '产假',
    value: 5
},{
    label: '婚假',
    value: 6
},{
    label: '丧假',
    value: 7
},{
    label: '工伤',
    value: 8
},{
    label: '陪产',
    value: 9
}]

const cpttOpts = [{
    label: '加工厂',
    value: 1
}, {
    label: '面料厂',
    value: 2
}, {
    label: '配套厂',
    value: 3
}, {
    label: '辅料厂',
    value: 4
},{
    label: '包材厂',
    value: 5
}]

const mtrlUnit = [{
    label: '面料',
    value: 1
}, {
    label: '非面料',
    value: 2
}
]

const mtrlCompany = [{
    label: '克',
    value: "克"
}, {
    label: '米',
    value: "米"
}, {
    label: '平方米',
    value: "平方米"
}, {
    label: '个',
    value: "个"
}]


const crftOpts = [{
    label: '车缝要求',
    value: 1
}, {
    label: '间距要求',
    value: 2
}, {
    label: '拉力要求',
    value: 3
}]

const smplStatus = [{
    label: '草拟',
    value: 0
}, {
    label: '在批',
    value: 1
}, {
    label: '生效',
    value: 2
}]

const smplToys = [{
    label: '狗玩具',
    value: '狗玩具'
}, {
    label: '猫玩具',
    value: '猫玩具'
}, {
    label: '狗用品',
    value: '狗用品'
}, {
    label: '猫用品',
    value: '猫用品'
}, {
    label: '其他',
    value: '其他'
}]

export default {
    educOpts,
    marrOpts,
    politicOpts,
    handBookOpts,
    bloodOpts,
    labOpts,
    signOpts,
    leavOpts,
    cpttOpts,
    mtrlUnit,
    crftOpts,
    smplStatus,
    mtrlCompany,
    smplToys
}